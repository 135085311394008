﻿
table td.shrink,
table th.shrink
{
    width: 1px;
    white-space: nowrap;
}



td.color-0
{
    background-color: #fad7dd;
}

td.color-1
{
    background-color: #fae0d7;
}

td.color-2
{
    background-color: #fdf3d9;
}

td.color-3
{
    background-color: #d5e5fa;
}

td.color-4
{
    background-color: #ccf7e5;
}



.card-body + .table-responsive .card-table thead th
{
    border-top-width: 1px;
}

.card-body + .card-table thead th
{
    border-top-width: 1px;
}

.table-responsive + .card-body
{
    border-top: $table-border-width solid $table-border-color;
}



table thead th.rotate-90
{
    vertical-align: bottom;
    height: 165px; // this is arbitrary based on current content
    span
    {
        writing-mode: vertical-rl;
        text-align: right;
    }
}

table.sortable .handle
{
    cursor: grab;
}