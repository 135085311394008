.progress.color-0
{
    background-color: lighten(desaturate($red, 50%), 35%);

    .progress-bar
    {
        background-color: $red;
    }
}

.progress.color-1
{
    background-color: lighten(desaturate($orange, 50%), 35%);

    .progress-bar
    {
        background-color: $orange;
    }
}
.progress.color-2
{
    background-color: lighten(desaturate($yellow, 50%), 32%);

    .progress-bar
    {
        background-color: $yellow;
    }
}

.progress.color-3
{
    background-color: lighten(desaturate($blue, 50%), 40%);

    .progress-bar
    {
        background-color: $blue;
    }
}

.progress.color-4
{
    background-color: lighten(desaturate($green, 50%), 45%);

    .progress-bar
    {
        background-color: $green;
    }
}

@for $i from 0 through 100
{
    .progress-#{$i}
    {
        width: percentage($i/100);
    }
}