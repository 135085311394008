﻿#mentors-modal
{
    .remove
    {
        display: none;
    }

    &.selected
    {
        .add
        {
            display: none;
        }

        .remove
        {
            display: inline-block;
        }
    }
}

ul.mentor-list
{
    .add
    {
        display: none;
    }
}