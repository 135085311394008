html
{
    user-select: none;
}

body
{
    overflow-y: scroll;

    &.modal-open
    {
        overflow-y: hidden;
    }
}

.text-color-0
{
    color: $red !important;
}

.text-color-1
{
    color: $orange !important;
}

.text-color-2
{
    color: $yellow !important;
}

.text-color-3
{
    color: $blue !important;
}

.text-color-4
{
    color: $green !important;
}


.fe.fe-heavy
{
    font-weight: 900;
}


.help
{
    cursor: help;
}


.fe-info[title]
{
    cursor: help;
}


.fill-primary
{
    fill: $primary;
}

.fill-white
{
    fill: $white;
}


.pe-none
{
    pointer-events: none;
}

table .chart
{
    height: 40px;
    width: 80px;
}


.avatar i.fe
{
    margin-bottom: 1px;
}

.avatar-status img
{
    -webkit-mask-image: url(#{$path-to-img}/masks/avatar-status.svg);
    mask-image: url(#{$path-to-img}/masks/avatar-status.svg);
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
}

.avatar-status .overlay
{
    background-color: #b1c2d9;
    content: '';
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 20%;
    height: 20%;
    border-radius: 50%;

    font-weight: 500;
    font-size: 0.3em;
    padding: 5px 6px;
    color: #fff;

    &:hover
    {
        color: $gray-300;
    }
}

.img-container img
{
    max-width: 100%;
}


.navbar .nav-item.active
{
    position: relative;

    &::after
    {
        content: '';
        background: $primary;
        width: 100%;
        height: 2px;
        display: block;
        margin-top: 12px;
        position: absolute;
    }
}

.chart.empty
{
    padding: 5rem 1rem;
    background-color: #fff;
    border: 1px dashed $gray-400;
    border-radius: .375rem;
    text-align: center;
    color: $gray-600;
    transition: all 0.2s ease-in-out;
    order: -1;
    z-index: 999;
}

button.feedback
{
    position: fixed;
    bottom: 40px;
    right: 40px;
    font-size: 35px;
    background-color: $light;
    border-radius: 50%;
    color: $secondary;
    width: 50px;
    height: 50px;
    display: block;
}


.alert-secondary a
{
    color: inherit;
    text-decoration: underline;

    &:hover
    {
        text-decoration: none;
    }
}

.modal.show
{
    display: block !important;
	background: rgba(0,0,0,0.1);
}

.card .ribbon
{
    position: absolute;
    left: -2px;
    top: -2px;
}


#page-loading
{
	position: absolute;
	width: 100%;
	color:red;
}


.pg
{
	width: 100%;
}

.dropdown-item .fe
{
	padding-top: 4px;
    vertical-align: top;
    display: inline-block;
	margin-right: 8px;
}


.toaster
{
	position: relative;
	pointer-events: none;

	> div {
		position: fixed;
		top: 30px;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		z-index: 99;
	}
}

.toast
{
	transition: opacity 150ms ease-in;
	opacity: 0;
	pointer-events: all;
	flex-basis: auto;
	margin: 0 auto;
	max-width: none;

	&.fadeIn
	{
		opacity: 1;
	}

	&.success
	{
		box-shadow: 0 0 1rem fade-out($success, .8);
		border-left: solid 2px $success;
	}

	&.danger
	{
		box-shadow: 0 0 1rem fade-out($danger, .8);
		border-left: solid 2px $danger;
	}
}
.toast-msg
{
	position: absolute;
	width: 100%;
	z-index: 1;
	text-align: center;
	padding: 0.6rem;
	opacity: 1;
	transition: opacity 250ms;

	&.fade-out
	{
		opacity: 0;
	}
}


.custom-checkbox .custom-control-input[disabled] ~ .custom-control-label::after
{
	cursor: not-allowed;
}

*[aria-title]
{
	position: relative;
	z-index: 1;

	&::before
	{
		display: block;
		content: attr(aria-title);
		border-radius: 5px;
		background-color: #95AAC9;
		color: #fff;
		font-size: 0.7rem;
		font-weight: 400;
		line-height: 1;
		text-transform: none;

		position: absolute;
		z-index: 999;
		top: -30px;
		padding: 4px 6px;

		left: 50%;
		width: auto;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);
		white-space: nowrap;

		pointer-events: none;
		transition: opacity 100ms ease-in-out;
		opacity: 0;
	}


	&:hover::before
	{
		opacity: 1;
	}

	&::after
	{
		display: block;
		content: '';
		width: 0px;
		height: 0px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid #95AAC9;

		position: absolute;
		z-index: 998;
		top: -11px;

		left: 50%;
		-webkit-transform: translateX(-50%);
		-moz-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		-o-transform: translateX(-50%);
		transform: translateX(-50%);

		pointer-events: none;
		transition: opacity 100ms ease-in-out;
		opacity: 0;
	}

	
	&.aria-danger::before
	{
		background-color: $danger;
	}
	&.aria-danger::after
	{
		border-top-color: $danger;
	}

	&:hover::after
	{
		opacity: 1;
	}
}