﻿
button
{
    border: 0;
    background: transparent;
    padding: 0;

    .spinner-border
    {
        margin: -3px 5px 0 0;
        vertical-align: middle;
    }
}

.btn
{
    white-space: nowrap;
}

.btn span.fe
{
    margin-bottom: 2px;
    display: inline-block;
    vertical-align: text-bottom;
}

.btn.btn-lg span.fe
{
    margin-bottom: 3px;
}


.btn.disabled,
.btn:disabled
{
    cursor: default;
}



a.google-sso
{
    span
    {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        width: 1px;
    }

    display: inline-block;
    width: 191px;
    height: 46px;
    //@error: url(/Areas/App/Assets/Images/btn_google_signin_dark_normal_web@2x.png);
    background-size: 100% 100%;

    &:focus
    {
        //background-image: url(/Areas/App/Assets/Images/btn_google_signin_dark_focus_web@2x.png);
    }

    &:active
    {
        //background-image: url(/Areas/App/Assets/Images/btn_google_signin_dark_pressed_web@2x.png);
    }
}

a.linkedin-sso
{
    span
    {
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        width: 1px;
    }

    display: inline-block;
    width: 215px;
    height: 40px;
    //background-image: url(/Areas/App/Assets/Images/Sign-In-Large---Default.png);
    background-size: 100% 100%;

    &:focus
    {
        //background-image: url(/Areas/App/Assets/Images/Sign-In-Large---Hover.png);
    }

    &:active
    {
        //background-image: url(/Areas/App/Assets/Images/Sign-In-Large---Active.png);
    }
}



@each $breakpoint in map-keys($grid-breakpoints)
{
    @include media-breakpoint-up($breakpoint)
    {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .btn#{$infix}-block
        {
            display: block;
            width: 100%;
        }

        .btn#{$infix}-inline-block
        {
            display: inline-block;
            width: auto;
        }
    }
}
