@font-face
{
    font-family: 'Cerebri Sans';
    src: url('#{$path-to-fonts}/cerebrisans-regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face
{
    font-family: 'Cerebri Sans';
    src: url('#{$path-to-fonts}/cerebrisans-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face
{
    font-family: 'Cerebri Sans';
    src: url('#{$path-to-fonts}/cerebrisans-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
